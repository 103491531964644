// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Hero from './Hero';
import Meta from '../shared/Meta';
import CostEstimator from './CostEstimator';
import Triple from './Triple';
import VendorsToChooseFrom from './VendorsToChooseFrom';
import CtaImage from './CtaImage';
import LogoList from './LogoList';
import AnyEvent from './AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl } from '../../modules/helpers';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './Triple.module.postcss';

class AsianCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Asian Food ${cityConfig.vehicle_type} Catering - Weddings, Parties & Events - ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`For your next wedding party or office event, why not treat your guests to delicious cuisines like Thai, Chinese, Vietnamese, Filipino, Korean, and more! Compare bids from available ${vehicleType}s using our free catering request below.`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Asian Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/ASIAN-HEADER.jpeg">
          <h1>Asian Food {cityConfig.vehicle_type} Catering</h1>
          <p>For your next wedding party or office event, why not treat your guests to delicious cuisines like Thai, Chinese, Vietnamese, Filipino, Korean, and more! Compare bids from available {vehicleType}s using our free catering request below.</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="PaddedSection">
          <div className="Container">
            <h3>How it works</h3>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering1.png"
                title="1. Answer a few questions"
                text={`Use our free online request form to share details about your upcoming catering event and gain access to over 500 of ${cityConfig.city_name}'s best food ${vehicleType}s.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering2.png"
                title="2. Receive quotes"
                text={`We’ll send you quotes from a selection of food ${vehicleType}s that fit your criteria. You can chat with them directly to find out more about their food or drink and coordinate the logistics of your event.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering3.png"
                title={`3. Book your ${vehicleType}`}
                text={`Choose your favorite food ${vehicleType}s and book directly with the vendor. Know your getting the best deal each time with no added commission of services fees!`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Asian1.jpeg" imgClass="img-asian1"/>
              <div className={styles.Cta_copy}>
                <h4>The best Asian food {vehicleType}s in {cityConfig.city_name}</h4>
                <p>
                From delicious pad thai to decadent dumplings, we've got the best Asian food {vehicleType}s in {cityConfig.city_name}. Try the famous banh mi sandwiches from the <Link to="/food-trucks/mami-tran">Mami Tran</Link> food {vehicleType}, and give your guests a healthy and scrumptious food break! At <Link to="/food-trucks/kaosamai">Kaosamai</Link> food {vehicleType}, you can find anything from pumpkin curry to mango salad! Each dish is based on authentic Thai dishes, served with a twist. Reach out to hundreds of vendors in one place and choose the best {vehicleType} for your next event!
                </p>
                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/ASian2.jpeg" imgClass="img-asian2"/>
              <div className={styles.Cta_copy}>
                <h4>Fusion Flavors</h4>
                <p>
                  Food {vehicleType}s like <Link to="/food-trucks/seoul-bowl-co">Seoul Bowl Co</Link> food {vehicleType} show off their innovative take on Korean BBQ, by serving it up in a custom build your own bowl. <Link to="/food-trucks/chebogz-filipino-food-truck">Chebogz Filipino</Link> food {vehicleType} serves up some of the best Filipino food in {cityConfig.city_name}, with people lining up daily for their lumpia! Or go classic, with <Link to="/food-trucks/panda-dim-sum">Panda Dim Sum</Link> food {vehicleType}, serving up dumplings from their yellow school bus that is guaranteed to have your guests coming back for more. On {cityConfig.site_title}, you can browse, compare and book over 500 of {cityConfig.city_name}'s best food {vehicleType}s, serving up delicious restaurant quality food from a kitchen on wheels.
                </p>
                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom options={{ food_categories: 'asian' }} />

        <CostEstimator title="Asian Catering Cost Estimator"/>

        <section className="PaddedSection">
          <div className="Container">
            <LogoList title="Some of the companies we serve" />
          </div>
        </section>

        <AnyEvent/>

        <section className="PaddedSection">
          <div className="Container">
            <div className={styles.BookingCta}>
              <h3>Think of us for your next event</h3>
              <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
              <a
                className="Button"
                href="/catering/register"
              >
                Food {cityConfig.vehicle_type} Catering
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

AsianCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AsianCatering);
